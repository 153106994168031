/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { Flex, Box, jsx } from 'theme-ui'
import { useTranslation } from 'react-i18next'

import C from '../constants'
import SEO from '../components/Seo'
import PageLayout from '../components/PageLayout'
import BannerWrapper from '../components/BannerWrapper'
import BackButton from '../components/BackButton'
import TextBlock from '../components/TextBlock'
import Banner from '../components/Banner'
import TextWidthLink from '../components/TextWidthLink'
import FreeTrialBanner from '../components/FreeTrialBanner'

const About = () => {
  const { t } = useTranslation()
  return (
    <PageLayout>
      <SEO title="About" />
      <BannerWrapper>
        <BackButton />
        <Box p={[3, 3, 4, 4]}>
          <TextBlock
            heading={t('AboutPage:pageHeading')}
            headingAsH1={true}
            textBeforeLink={t('AboutPage:pageHeadingDetail')}
          />
        </Box>
        <Banner
          imageName="man-laptop"
          imageAlt={t('ImageAlts:aboutPageImageAlt1')}
          textOrientation="left"
        >
          <TextBlock
            heading={t('AboutPage:firstBannerHeading')}
            textBeforeLink={t('AboutPage:firstBannerDetail')}
          />
        </Banner>
        <Banner
          imageName="group-walking"
          imageAlt={t('ImageAlts:aboutPageImageAlt2')}
          textOrientation="right"
        >
          <TextBlock
            heading={t('AboutPage:secondBannerHeading')}
            textBeforeLink={t('AboutPage:secondBannerDetail')}
          />
        </Banner>
        <Banner
          imageName="desk-ipad"
          imageAlt={t('ImageAlts:featurePageImageAlt')}
          textOrientation="left"
        >
          <Flex sx={{ flexDirection: 'column', justifyContent: 'center' }}>
            <TextBlock
              heading={t('AboutPage:thirdBannerHeading')}
              textBeforeLink={t('AboutPage:thirdBannerTextBeforeLink1')}
              linkText={t('AboutPage:thirdBannerLinkText1')}
              href={C.links.signupLink}
              textAfterLink={t('AboutPage:thirdBannerTextAfterLink1')}
              isInternal={false}
            />
            <Box pt={4}>
              <TextWidthLink
                textBeforeLink={t('AboutPage:thirdBannerTextBeforeLink2')}
                linkText={t('AboutPage:thirdBannerLinkText2')}
                href="/contact/"
                textAfterLink="."
              />
            </Box>
          </Flex>
        </Banner>
      </BannerWrapper>
      <FreeTrialBanner />
    </PageLayout>
  )
}

export default About
